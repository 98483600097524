<template>
  <div class="page">
    <navbar index="6" />
    <banner>
      <img src="../assets/img/banner.jpg" class="img-fluid" alt="之愈医疗">
    </banner>
    <div class="main-content">
      <div class="section-1">
        <div class="container">
          <h1>一、之愈医生</h1>
          <p>专注医患沟通的互联网医疗健康平台，之愈以“让更多人享受优质医疗服务”为使命，为用户提供基于互联网的在线咨询、预约服务、健康档案、私人医生、诊后随访、看病就医以及个性化的健康管理等服务。之愈为医务工作者提供的应用，医生的好帮手。之愈提供医生经纪服务和运营方案，打造医生品牌</p>
          <div class="row">
            <div class="col"><img src="../assets/apps/doctor/login.png" class="img-fluid" alt="之愈医疗"></div>
            <div class="col"><img src="../assets/apps/doctor/home.png" class="img-fluid" alt="之愈医疗"></div>
            <div class="col"><img src="../assets/apps/doctor/doctor.png" class="img-fluid" alt="之愈医疗"></div>
            <div class="col"><img src="../assets/apps/doctor/chat.png" class="img-fluid" alt="之愈医疗"></div>
            <div class="col"><img src="../assets/apps/doctor/wz.png" class="img-fluid" alt="之愈医疗"></div>
            <div class="col"><img src="../assets/apps/doctor/gh.png" class="img-fluid" alt="之愈医疗"></div>
            <div class="col"><img src="../assets/apps/doctor/user.png" class="img-fluid" alt="之愈医疗"></div>
            <div class="col"><img src="../assets/apps/doctor/mine.png" class="img-fluid" alt="之愈医疗"></div>
          </div>
          <h3 style="margin-top: 50px;">应用亮点</h3>
          <p>【医生工作站】医生自己的工作系统，属于自己的医疗团队，定制化服务方案。</p>
          <p>【在线服务】图文咨询、电话咨询、智能就诊等多种在线就医渠道，为你的健康保驾护航。</p>
          <p>【管家式服务】健康管家随时为您服务，全家健康，一手掌握，帮您更好了解家人的健康状况。</p>
          <p>【定制服务】通过我们的专业服务和医疗资源，为你的健康，为你定制贴心的健康服务套餐。</p>
        </div>
      </div>
      <div class="section-1">
        <div class="container">
          <h1>二、之愈小白咨询助手</h1>
          <p>之愈小白咨询助手以客户需求为中心，向患者提供服务解决方案。客户提交需求后，在首页可以看到订单详情，服务人员会根据要求完成服务，并反馈给客户，让患者就医更轻松。作为患者和医生沟通的桥梁，帮助收集患者的诊前就医资料，整理就诊后的检查报告。邀请医生入驻平台，协助进行在线服务，为患者提供在线咨询，电话咨询等服务。知识百科解决日常工作会议记录的功能，客服在线可以解答客户提交的问题，助力公司员工高效协同。</p>
          <div class="row">
            <div class="col">
              <img src="../assets/apps/xiaobai/login.png" class="img-fluid" alt="之愈医疗">
            </div>
            <div class="col">
              <img src="../assets/apps/xiaobai/home.png" class="img-fluid" alt="之愈医疗">
            </div>
            <div class="col">
              <img src="../assets/apps/xiaobai/chat.png" class="img-fluid" alt="之愈医疗">
            </div>
            <div class="col">
              <img src="../assets/apps/xiaobai/offline.png" class="img-fluid" alt="之愈医疗">
            </div>
            <div class="col">
              <img src="../assets/apps/xiaobai/wz.png" class="img-fluid" alt="之愈医疗">
            </div>
            <div class="col">
              <img src="../assets/apps/xiaobai/user.png" class="img-fluid" alt="之愈医疗">
            </div>
          </div>
          <h3 style="margin-top: 50px;">应用亮点</h3>
          <p>【服务中心】可在服务中心中选择想提供服务的订单【陪诊服务】安排预约患者的就诊时间、地点等</p>
          <p>【患者管理】对服务过的患者进行分组管理</p>
          <p>【健康报告】整理和填写患者就诊后的就诊资料，包括医生诊断、检查结果、用药记录、随访记录等</p>
          <p>【服务记录】查看所有服务过或者即将服务的订单信息</p>
          <p>【收入管理】查看自己的业绩提成、绑定银行卡</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  export default {
    name: 'Apps',
    components: {
      navbar,
      banner
    }
  }
</script>

<style lang="scss" scoped>
  .section-1 {
    min-width: 1300px;
    max-width: 1440px;
    margin: 0 auto;
    padding: 110px 0;
  }
</style>